import Block from '../../common.blocks/block'

export default class Product extends Block {
	static title = 'product'

	constructor($el) {
		super($el)

		this.anchor = this.$el.findElement('image-placeholder')

		const scrollItem =
			'.product__image-placeholder[data-product-id="' +
			localStorage.getItem('redirectedProduct') +
			'"]'
		if (
			localStorage.getItem('redirectedProduct') &&
			this.$el.hasModifier('overview')
		) {
			if ($(scrollItem).length > 0) {
				const position = $(scrollItem).offset().top - 150
				$('html, body').animate({ scrollTop: position }, 1000)
				localStorage.removeItem('redirectedProduct')

				$(window).bind('mousewheel', function () {
					$('html, body').stop()
				})
			}
		}

		this.anchor.on('click', () => {
			localStorage.setItem('redirectedProduct', this.anchor.data('productId'))
		})
	}
}
